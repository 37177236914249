import React, {useContext, useEffect, useState} from 'react';
import {Context} from '../../AppContext';
import {Center} from '../../Components/Layout/ContentWrapper';
import Preview from 'rev.sdk.js/Components/RichTextPreview';
import Image from '../../Components/Image';
import styled from 'styled-components';
import {Row, Text, Link, Divider} from '../../Widgets';
import {dateUtil} from '../../Utils';
import {normalizePath} from '../../Utils/pathUtil';
import RelatedBlog from './RelatedBlog';
import ShareLink from '../../Components/ShareLink';
import {Rev} from '@styled-icons/fa-brands';
const appConfig = require('../../data.json');
const queryString = require('query-string');

export default function BlogDetail({location}) {
  const app = useContext(Context);
  const parsed = queryString.parse(location.search);
  const {id} = parsed;
  const [record, setRecord] = useState();

  useEffect(() => {
    (async () => {
      app.actions.setLoading(true);
      try {
        let resp = await app.actions.getBlog({
          query: {
            id,
          },
        });
        setRecord(resp);
      } catch (err) {
        console.log(err);
      }
      app.actions.setLoading(false);
    })();
  }, [app.actions, id]);

  if (!record) {
    return <Wrapper></Wrapper>;
  }

  return (
    <React.Fragment>
      <Bg
        navbarHeight={
          appConfig.navbarHeightExcludePages.includes(
            normalizePath(location.pathname),
          )
            ? appConfig.navbarHeight
            : 0
        }
      />
      <Wrapper
        maxWidth={appConfig.blogMaxWidth}
        navbarHeight={
          appConfig.navbarHeightExcludePages.includes(
            normalizePath(location.pathname),
          )
            ? appConfig.navbarHeight
            : 0
        }>
        <Center>
          <Header>
            <h2>{record.title}</h2>
            <Divider paddingSize="md" color="#aaa" borderWidth={0.5} />

            <Text size="md" weight="500" style={{marginBottom: 10}}>
              {record.label[0]} {dateUtil.format(record.created, 'YYYY-MM-DD')}
            </Text>
            <Text size="xs" style={{marginBottom: 10}}>
              <Link to={`/articles`} style={{color: '#989898'}}>
                {appConfig.mapping.articles}
              </Link>
              {' > '}
              <Link
                to={`/articles/${record.label[0]}/`}
                style={{color: '#989898'}}>
                {record.label[0] || '-'}
              </Link>
              {' > '}
              {record.title}
            </Text>

            <Divider paddingSize="md" color="#aaa" borderWidth={0.5} />
          </Header>
          <Image
            src={record.image}
            style={{width: '100%', marginBottom: 40}}
            alt="blog detail image"
          />
          <PreviewWrapper>
            <Preview content={record.content} readOnly />
          </PreviewWrapper>
          <RelatedBlog label={record.label?.[0]} />
          <ShareLink item={record} />
        </Center>
      </Wrapper>
    </React.Fragment>
  );
}

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;

  & > h2 {
    font-size: ${appConfig.headingSize.lg}px;
    color: ${appConfig.colors.main};
    font-weight: 400;
    /* max-width: 550px; */
    margin-bottom: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
`;

const Wrapper = styled.div`
  padding-top: ${(props) => props.navbarHeight + 80}px;
  background-color: transparent;
  z-index: 1;

  @media only screen and (max-width: ${(props) => props.maxWidth}px) {
    padding: ${(props) =>
      `${props.navbarHeight + 80}px 20px 20px 20px` || `20px`};
  }
`;

const PreviewWrapper = styled.div`
  /* font-size: ${appConfig.fontSize.md}px; */
  margin-bottom: 50px;
  & p,
  blockquote,
  li,
  a {
    font-size: ${appConfig.fontSize.lg}px;
    line-height: 2;
  }
`;

const Bg = styled.div`
  background-color: ${appConfig.topSectionBackgroundColor};
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: ${(props) => 600 + props.navbarHeight}px;
  z-index: 0;
`;
